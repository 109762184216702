<template>
  <LeadStatusesPage
    title="Bloqueio - Automatização"
    description="Define quais status são bloqueados para automatização"
    param-id="leads_block_statuses_automation"
  />
</template>

<script>
import LeadStatusesPage from "@/components/Lead/Statuses/LeadStatusesPage.vue";

export default {
  components: {
    LeadStatusesPage,
  },
};
</script>
